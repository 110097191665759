<template>
  <div v-loading="loading">
    <el-row>
      <h2 style="margin-left: 10px; color: #666">
        {{ getValue(model.name) }}
      </h2>
      <el-col :span="16" class="cols">
        <el-card>
          <el-button @click="edit" type="success">
            Изменить
          </el-button>
          <el-button @click="dialogFormVisible = true" type="primary" v-if="model.rule === 1">
            Проверить корректность
          </el-button>
          <el-divider/>
          <div class="f-s-14-w-s-p-l">
            <b>ID:</b>
            <p class="m-t-5">
              {{ getValue(model.id) }}
            </p>
            <b>Код:</b>
            <p class="m-t-5">
              {{ getValue(model.code) }}
            </p>
            <b>Название:</b>
            <p class="m-t-5">
              {{ getValue(model.name) }}
            </p>
            <b>{{`Индекс КП${isKP ? '' : 'Р'}:` }}</b>
            <p class="m-t-5">
              {{ getValue(model.pki_id) }}
            </p>
            <b>Классификация:</b>
            <p class="m-t-5">
              {{ getValue(model.classification_name) }}
            </p>
            <b>Действующий:</b>
            <p class="m-t-5">
              {{ getValue(model.valid) }}
            </p>
            <b>Расчёт:</b>
            <p class="m-t-5">
              <katex-element v-if="model.rule_name.indexOf('`') >= 0" :expression="getFormula(model.rule_name)"/>
              <span v-else> {{ model.rule_name }}</span>
            </p>
            <b>В чём измеряется:</b>
            <p class="m-t-5">
              {{ getValue(model.type_name) }}
            </p>
            <b>Отчетные периоды:</b>
            <p class="m-t-5">
              {{ getArray(model.periods) }}
            </p>
            <div v-if="model.rule !== 0">
              <b>Направленность показателя:</b>
              <p class="m-t-5">
                {{ model.target_reachable === null ? '' : (model.target_reachable ? 'Повышаемый' : 'Понижаемый') }}
              </p>
              <b>Присвоение балла по уровню достижения целевого значения:</b>
              <p class="m-t-5">
                {{ getValue(model.rating_by_target) }}
              </p>
            </div>
            <div v-if="model.rating_by_target">
              <b>Целевое значение:</b>
              <p class="m-t-5">
                {{ getValue(model.target_value) }}
              </p>
            </div>
            <div v-if="model.clarification">
              <b>Поясняющий текст:</b>
              <p class="m-t-5">
                {{ getValue(model.clarification) }}
              </p>
            </div>
            <div v-if="model.classification_id === 4">
              <b>Блок:</b>
              <p class="m-t-5">
                {{ getValue(model.main_direction_activity_name) }}
              </p>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8" class="cols">
        <el-card>
          <label>История изменений</label>
          <el-divider/>
          <simple-table
            :list="logs"
            name="logs"
            :show-pagination="true"
            :columns="logsColumns"/>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" class="cols">
        <el-card class="m-b-10" v-if="rule_io.length > 0">
          <label>Значение из списка</label>
          <el-divider/>
          <simple-table
            :list="rule_io"
            name="rule_io"
            :show-pagination="false"
            :columns="ruleIoColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="symbols.length > 0">
          <label>Параметры</label>
          <el-divider/>
          <simple-table
            :list="symbols"
            name="symbols"
            :show-pagination="false"
            :columns="symbolsColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="masters.length > 0">
          <label>Зависит от</label>
          <el-divider/>
          <simple-table
            :list="masters"
            name="masters"
            :show-pagination="false"
            :columns="mastersColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="rule_ro.length > 0">
          <label>Диапазоны</label>
          <el-divider/>
          <simple-table
            :list="rule_ro"
            name="rule_ro"
            :show-pagination="false"
            :columns="ruleRoColumns"/>
          <el-divider/>
        </el-card>
      </el-col>
      <el-col :span="8" class="cols">
        <el-card>
          <label>Ответственные</label>
          <el-divider/>
          <el-table
            :data="users"
            row-key="id"
            border
            :tree-props="{ children: 'users' }">
            <el-table-column
              prop="name"
              label="ФИО"
              sortable>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="Проверка корректности" :visible.sync="dialogFormVisible" v-if="model.rule === 1">
      <el-form :model="checkForm" label-position="left" class="check-label">
        <p class="m-t-5">
          Формула расчета:
          <katex-element v-if="model.rule_name.indexOf('`') >= 0" :expression="getFormula(model.rule_name)"/>
          <span v-else> {{ model.rule_name }}</span>
        </p>
        <el-form-item
          v-for="item in symbols"
          :key="item.id">
          <template slot="label">
            <katex-element :expression="getFormula(item.designatio)"/>
            - {{ item.description }}
          </template>
          <input v-model="checkForm.symbols[item.id].value"
                 type="number"
                 class="el-input__inner">
        </el-form-item>
        <el-form-item
          v-for="item in masters"
          :key="item.id">
          <template slot="label">
            <katex-element :expression="getFormula(item.pki_id)"/>
            - {{ item.name }}
          </template>
          <input v-model="checkForm.masters[item.id].value"
                 type="number"
                 class="el-input__inner">
        </el-form-item>
        <div v-if="checkResult.result">
          <b>Результат:</b>
          <p class="m-t-5">
            {{ getValue(checkResult.result) }}
          </p>
          <b>Балл:</b>
          <p class="m-t-5">
            {{ getValue(checkResult.rating) }}
          </p>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">Отменить</el-button>
    <el-button type="primary" @click="checkPki">Отправить</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { getPKIView, getPKICheck } from '@/api/api'
import SimpleTable from '@/components/SimpleTable'
import AsciiMathParser from 'asciimath2tex'

export default {
  components: {
    SimpleTable
  },
  data () {
    return {
      isKP: false,
      dialogFormVisible: false,
      logs: [],
      checkResult: {
        result: null,
        rating: null
      },
      checkForm: {
        id: null,
        symbols: {},
        masters: {}
      },
      model: {
        id: null,
        accuracy: null,
        clarification: null,
        classification_id: null,
        classification_name: null,
        code: null,
        comment_only: null,
        rule_name: '',
        main_direction_activity_id: null,
        main_direction_activity_name: null,
        name: null,
        periods: [],
        pki_id: null,
        rating_by_target: null,
        rule: null,
        target_reachable: null,
        target_value: null,
        type: null,
        type_name: null,
        valid: null
      },
      rule_io: [],
      rule_ro: [],
      symbols: [],
      masters: [],
      users: [],
      loading: false,
      ruleIoColumns: [
        {
          field: 'value',
          label: 'Значение',
          align: 'left'
        },
        {
          field: 'rating',
          label: 'Балл',
          align: 'left'
        }
      ],
      ruleRoColumns: [
        {
          field: 'equals_min',
          label: 'Знак минимума',
          type: 'equals_min',
          align: 'left'
        },
        {
          field: 'input_min',
          label: 'Минимальное значение диапазона',
          align: 'left'
        },
        {
          field: 'equals_max',
          label: 'Знак максимума',
          type: 'equals_max',
          align: 'left'
        },
        {
          field: 'input_max',
          label: 'Максимальное значение диапазона',
          align: 'left'
        },
        {
          field: 'rating',
          label: 'Балл',
          align: 'left'
        }
      ],
      symbolsColumns: [
        {
          field: 'code',
          label: 'Код',
          align: 'left'
        },
        {
          field: 'description',
          label: 'Описание',
          align: 'left'
        },
        {
          field: 'designatio',
          label: 'Обозначение',
          align: 'left'
        },
        {
          field: 'type_name',
          label: 'В чем измеряется',
          align: 'left'
        },
        {
          field: 'required',
          label: 'Обязательный',
          align: 'left'
        }
      ],
      mastersColumns: [
        {
          field: 'code',
          label: 'Код',
          align: 'left'
        },
        {
          field: 'name',
          label: 'Название',
          align: 'left'
        },
        {
          field: 'type_name',
          label: 'В чем измеряется',
          align: 'left'
        },
        {
          field: 'pki_id',
          label: 'Обозначение',
          align: 'left'
        }
      ],
      usersColumns: [
        {
          field: 'fio',
          label: 'ФИО',
          align: 'left'
        }
      ],
      logsColumns: [
        {
          field: 'update_at',
          label: 'Дата изменения',
          type: 'date',
          align: 'left'
        },
        {
          field: 'user_fio',
          label: 'ФИО',
          align: 'left'
        },
        {
          field: 'id',
          label: 'Ссылка',
          type: 'link',
          route: '/control/pki/history'
        }
      ]
    }
  },
  created () {
    this.fetchData()
    this.isKP = this.$route.meta.isKP
  },
  methods: {
    checkPki () {
      getPKICheck(this.checkForm).then(response => {
        this.checkResult = response.data
      })
    },
    fetchData () {
      this.loading = true
      getPKIView(this.$route.params.id).then(response => {
        this.logs = response.data.logs
        this.model = response.data.model
        this.rule_io = response.data.rule_io
        this.masters = response.data.masters
        this.rule_ro = response.data.rule_ro
        this.symbols = response.data.symbols
        this.checkForm.id = this.model.id
        for (const item of this.symbols) {
          this.checkForm.symbols[item.id] = { value: null }
        }
        for (const item of this.masters) {
          this.checkForm.masters[item.id] = { value: null }
        }
        this.users = response.data.users
        this.loading = false
      })
    },
    getValue (val) {
      if ((typeof val) === 'boolean') {
        return val ? 'Да' : 'Нет'
      }
      if (val) {
        return val
      }
      return '\u2014'
    },
    getFormula (val) {
      val = val.replaceAll('`', '')
      const parser = new AsciiMathParser()
      return parser.parse(val).replaceAll('∑', 'Σ')
    },
    getArray (arr) {
      if (arr.length > 0) {
        return arr.join(', ')
      }
      return '\u2014'
    },
    edit () {
      this.$router.push({ name: this.$route.name.replace('view', 'update') })
    }
  }
}
</script>

<style scoped>
.cols {
  padding: 5px;
}

.check-label >>> label {
  line-height: 1.2;
  word-break: break-word;
  margin-bottom: 5px;
  font-weight: 100;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-5 {
  margin-top: 5px;
  line-height: 1.5;
}

.f-s-14-w-s-p-l {
  font-size: 14px;
  white-space: pre-line;
}
</style>
